import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mitt from "mitt";
import Toast, { POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
// imports all mbd components
import * as mdbvue from "mdb-vue-ui-kit";
import vClickOutside from "click-outside-vue3";
import perms from "./plugins/permissions";

const app = createApp(App);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

const defaultToastOptions = {
  // You can set your default options here
  transition: "Vue-Toastification__fade",
  position: POSITION.BOTTOM_RIGHT,
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
  maxToasts: 3,
  newestOnTop: false,
};
app.use(Toast, defaultToastOptions);
app.use(vClickOutside);
app.use(perms);

// if (process.env.NODE_ENV === "production") {

// }

/** preventing tree shaking of naive ui*/
import {
  // create naive ui
  create,
  // component
  NButton,
  NGrid,
  NGridItem,
  NFormItem,
  NTreeSelect,
  NCollapseItem,
  NCollapse,
  NConfigProvider,
  NSpace,
  NDropdown,
  NUpload,
  NDataTable,
  NTable,
  NAvatar,
  NText,
  NUploadDragger,
  NDatePicker,
  NSelect,
  NInput,
  NInputNumber,
  NPopover,
  NPopconfirm,
  NPopselect,
  NCascader,
  NTooltip,
  NRadioButton,
  NRadioGroup,
  NAvatarGroup,
  NTag,
  NIcon,
  NIconWrapper,
  NSpin,
  NModal,
  NForm,
  NDivider,
  NTabs,
  NTabPane,
  NSkeleton,
  NCheckbox,
  NButtonGroup,
} from "naive-ui";

const naive = create({
  components: [
    NPopconfirm,
    NDivider,
    NModal,
    NInput,
    NTable,
    NButton,
    NGrid,
    NGridItem,
    NFormItem,
    NTreeSelect,
    NCollapseItem,
    NCollapse,
    NConfigProvider,
    NSpace,
    NDropdown,
    NUpload,
    NDataTable,
    NAvatar,
    NText,
    NUploadDragger,
    NDatePicker,
    NSelect,
    NInputNumber,
    NPopover,
    NPopselect,
    NCascader,
    NTooltip,
    NRadioButton,
    NRadioGroup,
    NAvatarGroup,
    NTag,
    NIcon,
    NIconWrapper,
    NSpin,
    NForm,
    NTabs,
    NTabPane,
    NSkeleton,
    NCheckbox,
    NButtonGroup,
  ],
});

app.use(naive);
app.use(router);
app.use(store);
app.mount("#app");

/**
 *  includes the following:
 *  MDBBadge - MDBBtn - MDBCardBody - MDBCardTitle - MDBCardText -
 *  MDBCardImg - MDBCardHeader - MDBCardFooter - MDBCardLink - MDBCardGroup -
 *  MDBCarousel - MDBBtnGroup - MDBBtnClose - MDBCard - MDBListGroup -
 *  MDBListGroupItem - MDBProgress - MDBProgressBar - MDBSpinner - MDBCollapse -
 *  MDBDropdown - MDBDropdownToggle - MDBDropdownMenu - MDBDropdownItem - MDBTooltip -
 *  MDBPopover - MDBModalHeader - MDBModalTitle - MDBModalBody - MDBModalFooter - MDBAccordion -
 *  MDBAccordionItem - MDBIcon - MDBNavbarToggler - MDBNavbarBrand - MDBNavbarNav - MDBNavbarItem -
 *  MDBPagination - MDBPageNav - MDBPageItem - MDBBreadcrumb - MDBBreadcrumbItem - MDBFooter - MDBTabs -
 *  MDBTabNav - MDBTabItem - MDBTabPane - MDBTabContent - MDBCol - MDBRow - MDBContainer - MDBTable - MDBInput -
 *  MDBTextarea - MDBCheckbox - MDBRadio - MDBFile - MDBRange - MDBSwitch - mdbRipple - mdbScrollspy - MDBModal -
 *  MDBAlert - MDBAnimation - MDBChart - MDBRating - MDBRatingItem - MDBStepper - MDBStepperStep - MDBStepperHead -
 *  MDBStepperContent - MDBStepperForm - MDBPopconfirm - MDBLightbox - MDBLightboxItem - MDBDatatable - MDBDatepicker -
 *  MDBSelect - MDBAutocomplete - MDBSideNav - MDBSideNavMenu - MDBSideNavItem - MDBSideNavLink - MDBNavbar - MDBScrollbar -
 *  MDBLoading - MDBTimepicker - MDBToast - MDBChip - MDBChipsInput - MDBMultiRange - mdbTouch - mdbSticky - mdbLazy -
 *  mdbInfiniteScroll - mdbSmoothScroll
 */
for (const component in mdbvue) {
  app.component(component, mdbvue[component]);
}
// for (const component in matIcons) {
//   app.component(component, matIcons[component]);
// }
